import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

if (['test', 'production'].includes(process.env.REACT_APP_ENV)) {
	Sentry.init({
		dsn: 'https://fa7c8e1123fb4c17bc46d48ed312e77a@o1160869.ingest.sentry.io/6245665',
		integrations: [new BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.01,
		environment: process.env.REACT_APP_ENV,
	});
}
