import axios from 'axios';
import { BACKEND_URL } from './config';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import useStore from 'stores/user';
import { useState } from 'react';

const headers = {
	Accept: 'application/json',
};

/**
 * to get the response of the api call
 */
const utilsMakeApiCall = (method, route, headers, params, formdata) => {
	return new Promise((resolve, reject) => {
		axios({
			method: method,
			url: `${BACKEND_URL}${route}`,
			headers: headers,
			params: params,
			data: formdata,
			withCredentials: false,
		})
			.then((response) => {
				if (response.data && response.data.status) {
					resolve({ data: response.data });
				} else {
					throw new Error('');
				}
			})
			.catch((error) => {
				reject({ errorOccured: true });
			});
	});
};

export const utilsFetchSubjectByCourse = (courseId) => {
	let route = `rest/v1/subjects/fetch-by-course/${courseId}`;
	return new Promise((resolve, reject) => {
		utilsMakeApiCall('GET', route, headers, null, null).then((data) => {
			let subjectData = data?.data?.subjects;
			resolve(subjectData);
		});
	});
};

export const utilsFetchTopicsBySubject = (subjectId) => {
	let route = `rest/v1/topics/fetch-by-subject/${subjectId}`;
	return new Promise((resolve, reject) => {
		utilsMakeApiCall('GET', route, headers, null, null).then((data) => {
			let topicData = data?.data?.topics;
			resolve(topicData);
		});
	});
};
// const attachmentHeader = {
// 	Accept: 'application/json',
// 	'Content-Type': 'multipart/form-data',
// };

export const API = axios.create({
	baseURL: BACKEND_URL,
	withCredentials: false,
});

// Teachmore hack
API.interceptors.request.use(
	(config) => {
		const userState = useStore.getState();
		const tmoreToken = userState?.tmoreToken;
		if (tmoreToken) config.headers['tmore-token'] = tmoreToken;
		return config;
	},
	(err) => Promise.reject(err),
);

export const fetchCourses = async () => {
	const { data } = await API.get('rest/v1/courses/fetch-all');
	return data.courses;
};

export const fetchSubjectsByCourse = async (courseId) => {
	const data = await API.get(`rest/v1/subjects/fetch-by-course/${courseId}`);
	return data?.data?.subjects;
};

export const fetchTopcisBySubject = async (subjectId) => {
	const data = await API.get(`rest/v1/topics/fetch-by-subject/${subjectId}`);
	return data?.data?.topics;
};

export const fetchTopicsByIds = async (ids) => {
	const resp = await API.get(`rest/v1/topics/fetch-by-ids?ids=${ids}`);
	return resp?.data?.topics;
};

export const fetchInitialQuestions = async ({
	topics,
	difficulty,
	questionTypes,
	excludeQuestions,
}) => {
	let { data } = await API.post(
		`rest/v1/questions/fetch-initial-test-questions`,
		{
			topics,
			questionTypes,
			difficulty,
			excludeQuestions,
		},
	);

	let questions = groupBy(data, 'type');
	questions = mapValues(questions, (qnsByType) =>
		groupBy(qnsByType, 'tags.0.topic_id'),
	);

	return questions;
};

export const fetchExamData = async (paperId, fetchUserInfos) => {
	const data = await API.get(
		`rest/v1/exams/get-completed-exams-by-paper/${paperId}`,
		{
			params: {
				fetchUserInfos,
			},
		},
	);
	return data;
};

export const fetchEvaluationData = async (examId) => {
	const data = await API.get(`rest/v1/evaluate/${examId}`);
	return data;
};

export const evaluateQuestion = async (examId, data) => {
	const data1 = await API.put(`rest/v1/evaluate/${examId}/set-marks`, data);
	return data1;
};

export const evaluateCompleteFlag = async (examId) => {
	const data = await API.post(`rest/v1/evaluate/${examId}/finish`);
	return data;
};
