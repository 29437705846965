import create from 'zustand';
import * as Sentry from '@sentry/browser';
import { devtools, persist } from 'zustand/middleware';
import { API } from 'utils/api';
import { login, tmoreLogin } from 'api/session';

const useStore = create(
	devtools(
		persist(
			(set, get) => ({
				user: null,
				tmToken: null,
				classRoomId: null,
				source: null,
				tmoreToken: null,
				os: null,
				appVersion: null,
				appId: null,

				initializeSession: async (query) => {
					const newTmToken = query.get('token');
					const classRoomId = query.get('class_id');
					const source = query.get('source');
					const os = query.get('os');
					const appVersion =
						query.get('app-version') || query.get('app_version');
					const appId = query.get('app_id');
					const { tmToken, user, classRoomId: classRoomIdOld } = get();

					if (classRoomId)
						API.defaults.headers.common['tm-classroom-id'] = classRoomId;
					// IF token changed, relogin user
					if (newTmToken !== tmToken) {
						const resData = await login({ token: newTmToken });
						set({
							user: resData.user,
							tmToken: newTmToken,
							classRoomId,
							source,
							os,
							appVersion,
							appId,
						});

						sessionStorage &&
							sessionStorage.setItem('userUuid', resData.user.userUuid);

						API.defaults.headers.common['tm-user-uuid'] = resData.user.userUuid;

						if (['test', 'production'].includes(process.env.REACT_APP_ENV)) {
							Sentry.setUser({ id: resData?.user?._id });
						}

						return { user: resData.user, classRoomId };
					}

					API.defaults.headers.common['tm-user-uuid'] =
						(sessionStorage && sessionStorage.getItem('userUuid')) ||
						user.userUuid;

					return { user, classRoomId: classRoomIdOld, source };
				},

				setClassRoomHeader: (query) => {
					let classRoomId = query.get('class_id');
					if (!classRoomId) {
						({ classRoomId } = get());
					}
					if (classRoomId)
						API.defaults.headers.common['tm-classroom-id'] = classRoomId;
				},
				setUser: (user) => {
					set({ user });
				},
				initTmoreSession: async (userId, role) => {
					const data = await tmoreLogin({ userId, role });
					set({ user: data.user, tmoreToken: data.token, source: 'web' });
				},
			}),
			{ name: 'user' },
		),
		{ name: 'user' },
	),
);

export default useStore;
